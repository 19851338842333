import axios from "axios";

const isObject = (item) => {
  return (item && typeof item === 'object' && !Array.isArray(item));
}

const isElementHidden = (element) => {
  return element.style.position === 'fixed' ?
    window.getComputedStyle(element).style.display === 'none' :
    element.classList.contains('hidden') || element.offsetParent === null
}

const deepMerge = (target, ...sources) => {
  if (!sources.length) return target;
  const source = sources.shift();

  if (isObject(target) && isObject(source)) {
    for (const key in source) {
      if (isObject(source[key])) {
        if (!target[key]) Object.assign(target, { [key]: {} });
        deepMerge(target[key], source[key]);
      } else {
        Object.assign(target, { [key]: source[key] });
      }
    }
  }

  return deepMerge(target, ...sources);
}

const onDocumentInteracted = (callback) => {
  document.addEventListener('turbo:load', callback);
  document.addEventListener('readystatechange', event => {
    if(event.target.readyState === 'complete'){
      callback();
    }
  });
}

const jsRequestHeaders = {
  headers: { 'Accept': 'application/javascript', 'Content-Type': 'application/javascript;charset=UTF-8' }
}
const getElementHeight = (element) => {
  return parseFloat(getComputedStyle(element, null).height.replace("px", ""))
}

const trackAction = (type, scope, data = {}) => {
  const qs = require('qs');
  axios.post(Env.routes.tracking_permalink, qs.stringify({
    type: type,
    scope: scope,
    authenticity_token: document.querySelector('meta[name=csrf-token]').getAttribute('content'),
    ...data
  })).catch(error => {
    console.log(error);
  })
}

const isInViewport = (elem, options) => {
  options = options || {}
  if(elem.style.position == 'fixed' || elem.style.position == 'sticky' || options.force){
    return false
    // return options.considerPositionFixedAsViewport || false;
  }
  var bounding = elem.getBoundingClientRect();
  return (
    bounding.top >= 0 &&
    bounding.left >= 0 &&
    bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
    bounding.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
};

const createLottie = (src, width, height, options) => {
  options = options || {}
  let player = document.createElement('lottie-player');
  player.setAttribute('autoplay', options.autoplay || "autoplay");
  player.setAttribute('src', Lotties[src]);
  player.setAttribute('id', options.id || `${src}-${Date.now()}`);
  player.setAttribute('class', options.class || 'lottie');
  player.setAttribute('speed', options.speed || 1);
  if(options.loop) { player.setAttribute('loop', 'loop') }
  if(options.height){ player.style.height = height }
  if(options.width){ player.style.width = width }
  return player;
}

const insertStylesheet = (source) => {
  const container = document.head
  // const container = document.querySelector('[data-scripts]')
  window.loadedStylesheets = window.loadedStylesheets || {}
  if(!window.loadedStylesheets[source]){
    const modern = document.createElement('link');
    modern.setAttribute('rel', 'preload');
    modern.setAttribute('type', 'text/css');
    modern.setAttribute('as', 'style');
    modern.setAttribute('href', source);
    const legacy = document.createElement('link');
    legacy.setAttribute('rel', 'stylesheet');
    legacy.setAttribute('media', 'print');
    legacy.setAttribute('onload', 'this.media="all"');
    legacy.setAttribute('href', source);
    container.appendChild(modern);
    container.appendChild(legacy);
    window.loadedStylesheets[source] = true
  }
}

const insertScript = (parent, source, callbackOrEventName) => {
  window.loadedScripts = window.loadedScripts || {}
  if(!window.loadedScripts[source]){
    let script = document.createElement('script');
    let prior = document.getElementsByTagName('script')[0];
    script.async = 1;
    script.onload = script.onreadystatechange = function( _, isAbort){
      if(isAbort || !script.readyState || /loaded|complete/.test(script.readyState) ) {
        script.onload = script.onreadystatechange = null;
        script = undefined;
        if(typeof callbackOrEventName === 'string'){
          const event = new CustomEvent(`${callbackOrEventName}:ready`, {})
          document.dispatchEvent(event);
        } else {
          if(!isAbort && callbackOrEventName) setTimeout(callbackOrEventName, 200);
        }
      }
    };
    script.src = source;
    parent.appendChild(script)
    window.loadedScripts[source] = true
  } else {
    if(typeof callbackOrEventName === 'string'){
      setTimeout(() => {
        const event = new CustomEvent(`${callbackOrEventName}:ready`, {})
        document.dispatchEvent(event);
      }, 100)
    } else {
      if(callbackOrEventName) { callbackOrEventName() }
    }
  }
}

const isFalsy = (statement) => {
  statement = String(statement)
  return statement === 'false' || statement === 'null' || statement === 'undefined' || statement === '0'
}

const isTruthy = (statement) => {
  return !isFalsy(statement)
}

export {
  isTruthy, isFalsy, isElementHidden, deepMerge, insertScript, insertStylesheet, onDocumentInteracted, getElementHeight, trackAction, jsRequestHeaders, createLottie, isInViewport
}
